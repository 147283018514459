




















import Vue from "vue";
import Component from "vue-class-component";
import auth from "@/store/auth";

@Component
export default class Home extends Vue {
  email = '';
  code = '';
  error = '';
  loading = true;

  async mounted() {
    this.email = this.$route.query.email as string;
    this.code = this.$route.query.code as string;

    try {
      if (!this.email || !this.code) {
        throw Error('Your reset password link is invalid');
      }
  
      this.$router.push({ query: {} });

      await auth.resetPasswordWithCode({ email: this.email, code: this.code });
    } catch (err) {
      this.error = err.message;
    }
    this.loading = false;
  }
}
